import React, { useEffect, useRef, useState } from "react";
import * as Api from "../ApiV2";
import { StyledTreeTOC } from "../assets/style/components/treeToc";
import {
  Tree,
  TreeRef,
  TreeItem,
  TreeItemRenderContext,
  TreeInformation,
  TreeItemIndex,
  DraggingPosition,
  ControlledTreeEnvironment,
} from "react-complex-tree";
import "react-complex-tree/lib/style-modern.css";
import {
  ChevronDown16Regular,
  ChevronUp16Regular,
} from "@fluentui/react-icons";
export interface LxTreeColumn {
  name: string;
  flexGrow?: number;
  title: string;
  width?: number;
  order?: "asc" | "desc";
  AllowOrder?: boolean;
  className?: string;
  render?: (item: TreeItem<any>, column: LxTreeColumn) => React.ReactNode;
}
export interface LXTreeViewProps {
  treeId: string;
  className?: string;
  canDragAndDrop?: boolean;
  data: any;
  columns?: LxTreeColumn[];
  onContextMenu?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    itemId: TreeItem<any>
  ) => void;
  onDrop?: (
    items: TreeItem<Api.TocTabInfo>[],
    target: DraggingPosition,
    treeData: any,
    selectedItems: TreeItemIndex[]
  ) => void;
  onSelectedItemsChanged?: (items: TreeItemIndex[]) => void;
  renderColItem?: (item: TreeItem<any>) => React.ReactNode;
  onOrder?: (column: LxTreeColumn) => void;
}
export const LXTreeView = ({
  treeId,
  canDragAndDrop,
  className,
  columns,
  data,
  onDrop,
  onOrder,
  onContextMenu,
  onSelectedItemsChanged,
  renderColItem,
}: LXTreeViewProps) => {
  const [focusedItem, setFocusedItem] = useState<TreeItemIndex>();
  const [selectedItems, setSelectedItems] = useState<TreeItemIndex[]>([]);
  const [firstSelected, setFirstSelected] = useState<TreeItemIndex>();
  const [expandedItems, setExpandedItems] = useState<TreeItemIndex[]>([]);
  const [itemTrees, setItemTrees] = useState<any>(data);
  const [Columns, setColumns] = useState<LxTreeColumn[]>(columns || []);
  const [columnsOrder, setColumnsOrder] = useState<LxTreeColumn>();
  const tree = useRef<TreeRef>(null);
  const startPosition = useRef<{
    x: number;
    y: number;
    col: LxTreeColumn | undefined;
  }>({ x: 0, y: 0, col: undefined });
  useEffect(() => {
    const items = [] as TreeItemIndex[];
    for (const field in itemTrees) {
      if (field != "root" && itemTrees[field].children.length > 0) {
        items.push(field);
      }
    }
    setExpandedItems(items);
  }, [itemTrees]);
  useEffect(() => {
    setColumns(columns || []);
  }, [columns]);
  useEffect(() => {
    setItemTrees({ ...data });
  }, [data]);
  useEffect(() => {
    if (selectedItems.length == 1) {
      setFirstSelected(selectedItems[0]);
    }
    if (selectedItems.length == 0) {
      setFirstSelected(undefined);
    }
  }, [selectedItems]);
  const dropNodes = (items: TreeItem<any>[], target: DraggingPosition): any => {
    let ids = items.map((o) => o.index);
    if (target.targetType == "between-items") {
      const idViTriLonHons: string[] = [];
      const idViTriNhoHons: string[] = [];
      let linearIndex = target.childIndex;
      if (target.parentItem != "root") {
        linearIndex = target.childIndex;
      }
      ((itemTrees[target.parentItem].children as string[]) || []).forEach(
        (o, index) => {
          if (index < linearIndex) {
            if (!ids.includes(o)) {
              idViTriNhoHons.push(o);
            }
          } else {
            if (!ids.includes(o)) {
              idViTriLonHons.push(o);
            }
          }
        }
      );
      itemTrees[target.parentItem].children = [
        ...idViTriNhoHons,
        ...ids,
        ...idViTriLonHons,
      ];
      // }
      for (const field in itemTrees) {
        if (field != target.parentItem)
          itemTrees[field].children = (
            (itemTrees[field].children as string[]) || []
          ).filter((o) => !ids.includes(o));
      }
    } else if (target.targetType == "item") {
      const item = itemTrees[target.targetItem];

      ids = ids.filter(
        (o) => !(item.children as string[]).includes(o as string)
      );
      if (ids.length == 0) return itemTrees;
      const idUnSelects = ((itemTrees as any).root.children || []).filter(
        (o: string) => !ids.includes(o)
      );
      (itemTrees as any).root.children = idUnSelects;
      item.children = [...item.children, ...ids];
      for (const field in itemTrees) {
        if (field != "root" && field != target.targetItem)
          itemTrees[field].children = (
            (itemTrees[field].children as string[]) || []
          ).filter((o) => !ids.includes(o));
      }
    }
    setItemTrees({ ...itemTrees });
    return itemTrees;
  };
  // Hàm để chuyển cây thành mảng phẳng
  function flattenTree(treeData: any, nodeId = "root") {
    const result: any[] = [];

    function traverse(nodeId: string) {
      const node = treeData[nodeId];
      if (!node) return;

      // Nếu node có dữ liệu, thêm vào mảng kết quả
      if (node.data) {
        result.push({ id: nodeId, ...node.data });
      }

      // Đệ quy với từng phần tử con
      if (node.children) {
        (node.children || []).forEach((childId: string) => traverse(childId));
      }
    }
    traverse(nodeId);
    return result;
  }
  const GetViewState = () => {
    const obj = {} as any;
    obj[`${treeId}`] = { selectedItems, expandedItems, focusedItem };
    return obj;
  };
  const handleResize = (e: React.MouseEvent, column: LxTreeColumn) => {
    const startX = e.clientX;
    const startWidth = column.width || 0;
    const indexCol = Columns.indexOf(column);
    const nextIndex = indexCol + 1;
    const preIndex = indexCol - 1;
    const nextColumn = Columns[nextIndex];
    const onMouseMove = (event: MouseEvent) => {
      let newWidth = Math.max(startWidth + event.clientX - startX, 30); // Minimum width
      if (
        Columns.indexOf(column) == Columns.length - 1 ||
        Columns[0].flexGrow == 1
      ) {
        newWidth = Math.max(startWidth + startX - event.clientX, 30); // Minimum width
      }
      // if (column.width && newWidth < column.width) {
      //   nextColumn.width = (nextColumn.width || 0) + (column.width - newWidth);
      // }
      setColumns((prev) => {
        return prev.map((col) => {
          if (col.name === column.name) {
            return { ...col, width: newWidth };
          }
          return col;
        });
      });
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };
  if (!treeId) return null;
  return (
    <StyledTreeTOC
      style={{ position: "relative" }}
      id={treeId}
      className={className}
    >
      <div
        className={`header-row`}
        style={{
          height: 30,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {Columns?.map((col, index) => {
          return (
            <div
              key={index}
              className="col-header"
              style={{
                flexGrow: col.flexGrow || 0,
                flexBasis: col.width || "auto",
              }}
              onClick={() => {
                if (col.AllowOrder) {
                  setColumnsOrder(col);
                  setColumns(
                    Columns.map((o) => {
                      if (o.name == col.name) {
                        return {
                          ...o,
                          order: o.order == "asc" ? "desc" : "asc",
                        };
                      }
                      return { ...o, order: undefined };
                    })
                  );
                  onOrder && onOrder(col);
                }
              }}
            >
              {col.title}
              {col.AllowOrder &&
                columnsOrder?.name == col.name &&
                (col.order == "asc" ? (
                  <ChevronDown16Regular
                    style={{
                      position: "absolute",
                      right: 0,
                    }}
                  ></ChevronDown16Regular>
                ) : (
                  <ChevronUp16Regular
                    style={{
                      position: "absolute",
                      right: 0,
                    }}
                  ></ChevronUp16Regular>
                ))}
              {!col.flexGrow && (
                <div
                  className={`resizer ${
                    Columns.indexOf(col) == Columns.length - 1 ||
                    Columns[0].flexGrow == 1
                      ? "left"
                      : ""
                  }`}
                  onMouseDown={(e) => {
                    handleResize(e, col);
                  }}
                ></div>
              )}
            </div>
          );
        })}
      </div>

      <ControlledTreeEnvironment<any>
        canDropOnFolder
        canSearch={false}
        canDragAndDrop={canDragAndDrop}
        canReorderItems={true}
        onDrop={(e, ta) => {
          const treeData = dropNodes(e, ta);
          onDrop && onDrop(e, ta, treeData, selectedItems);
        }}
        items={itemTrees as any}
        getItemTitle={(item) => item.data.uniqueId || ""}
        onSelectItems={(items) => {
          setSelectedItems(items);
          onSelectedItemsChanged && onSelectedItemsChanged(items);
        }}
        onFocusItem={(item) => {
          setFocusedItem(item.index);
        }}
        onExpandItem={(item) => {
          setExpandedItems([...expandedItems, item.index]);
        }}
        onCollapseItem={(item) => {
          setExpandedItems(expandedItems.filter((o) => o !== item.index));
        }}
        viewState={GetViewState()}
        renderItem={(props: {
          item: TreeItem<any>;
          depth: number;
          children: React.ReactNode;
          title: React.ReactNode;
          arrow: React.ReactNode;
          context: TreeItemRenderContext<never>;
          info: TreeInformation;
        }) => {
          props.context.interactiveElementProps.onClick = (ev) => {
            if (ev.shiftKey) {
              const flatList = flattenTree(itemTrees);
              const lstId = flatList.map((item) => {
                return item.id;
              });
              const id = (ev.target as any).parentNode.parentNode.getAttribute(
                "data-rct-item-id"
              );
              const firstSelectedItem = firstSelected || id;
              const startIndex = lstId.indexOf(firstSelectedItem);
              const endIndex = lstId.indexOf(id);
              const itemsInRange = lstId.slice(
                Math.min(startIndex, endIndex),
                Math.max(startIndex, endIndex) + 1
              );
              setSelectedItems(itemsInRange);
              onSelectedItemsChanged && onSelectedItemsChanged(itemsInRange);
              return;
            }
            if (ev.ctrlKey) {
              const id = (ev.target as any).parentNode.parentNode.getAttribute(
                "data-rct-item-id"
              );
              if (selectedItems.find((o) => o == id)) {
                props.context.unselectItem();
              } else {
                props.context.addToSelectedItems();
              }
            } else {
              props.context.selectItem();
            }
          };
          props.context.interactiveElementProps.onKeyUp = (ev) => {
            ev.stopPropagation();
          };
          props.context.interactiveElementProps.onKeyDown = (event) => {
            event.stopPropagation();
            (document.activeElement as any).focus();
            const inputs = document
              .querySelector("#" + treeId)
              ?.querySelectorAll("input[type='text']");
            const arrElement: Element[] = [];
            inputs?.forEach((element) => {
              arrElement.push(element);
            });
            const currentIndex = arrElement.indexOf(
              document.activeElement as Element
            );
            if (event.key === "ArrowDown" || event.key === "ArrowUp") {
              if (
                event.key === "ArrowDown" &&
                currentIndex < arrElement.length - 1
              ) {
                (arrElement[currentIndex + 1] as any).focus();
                event.preventDefault(); // Prevent default arrow down behavior
              } else if (event.key === "ArrowUp" && currentIndex > 0) {
                (arrElement[currentIndex - 1] as any).focus();
                event.preventDefault(); // Prevent default arrow up behavior
              }
            }
          };
          props.context.interactiveElementProps.onDrop = (ev) => {
            document.querySelectorAll(".row").forEach((element) => {
              element.removeAttribute("style");
            });
          };
          props.context.interactiveElementProps.onFocus = () => {};
          props.context.interactiveElementProps.onContextMenu = (e) => {
            e.preventDefault();
            if (selectedItems.length == 1 || selectedItems.length == 0) {
              props.context.selectItem();
            }
            setTimeout(() => {
              onContextMenu && onContextMenu(e, props.item);
            }, 100);
          };
          return (
            <li {...props.context.itemContainerWithChildrenProps}>
              <div
                {...props.context.itemContainerWithoutChildrenProps}
                {...(props.context.interactiveElementProps as any)}
                className="row"
                onDragOver={(ev) => {
                  const id = (ev.currentTarget as any).getAttribute(
                    "data-rct-item-id"
                  );
                  const item = itemTrees[id];
                  if (item.canMove) {
                    let parent = ev.currentTarget as any;
                    while ((parent != null && parent.className) != "row") {
                      parent = parent.parentElement;
                    }
                    if (parent) {
                      // Lấy tọa độ của div dropArea trong trang
                      const rect = parent.getBoundingClientRect();

                      // Tính tọa độ chuột trong div dropArea
                      const mouseX1 = ev.clientX - rect.left;
                      const mouseY1 = ev.clientY - rect.top;
                      if (mouseY1 > 7.6 && mouseY1 < 35) {
                        parent.setAttribute(
                          "style",
                          "background-color: #dbefff"
                        );
                      } else {
                        parent.removeAttribute("style");
                      }
                    }
                  }
                }}
                onDragLeave={(ev) => {
                  let parent = ev.currentTarget as any;
                  while ((parent != null && parent.className) != "row") {
                    parent = parent.parentElement;
                  }
                  parent && parent.removeAttribute("style");
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {props.item && (props.item.children?.length || 0) > 0
                    ? props.arrow
                    : ""}
                </div>
                {Columns?.map((col, index) => {
                  return col.render && col.render(props.item, col);
                })}
              </div>
              {props.children}
            </li>
          );
        }}
      >
        <Tree treeId={treeId} rootItem="root" ref={tree} />
      </ControlledTreeEnvironment>
    </StyledTreeTOC>
  );
};
