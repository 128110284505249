import React, { useEffect, useRef, useState } from "react";
import * as Api from "../../../ApiV2";
import { StyledTreeTOC } from "../../../assets/style/components/treeToc";
import {
  TreeRef,
  TreeItem,
  TreeItemIndex,
  DraggingPosition,
} from "react-complex-tree";
import "react-complex-tree/lib/style-modern.css";
import { useLang } from "../../../i18n/useLang";
import { TocContextMenu } from "./tocContextMenu";
import { useBoolean } from "@fluentui/react-hooks";
import { docuviewareMainState, TOCState, trackingState } from "../../../recoil";
import { useRecoilState } from "recoil";
import { AddBlankPageModal } from "../../../modal/AddBlankPageModal";
import { DataCache } from "../../../config/constant";
import { documentService } from "../../../service/document";
import { StyledOCRProgress } from "../../../assets/style/components/tabs";
import { LXTreeView } from "../../../control/LXTreeView";
import { v4 } from "uuid";
export function BuildDataTree(tocs: Api.TocTabInfo[]) {
  const dataResult: any = {
    root: {
      index: "root",
      canMove: true,
      isFolder: false,
      children: tocs
        .filter((o) => o.parentId == "00000000-0000-0000-0000-000000000000")
        .map((o) => o.uniqueId),
      canRename: true,
      data: { id: "00000000-0000-0000-0000-000000000000" },
    },
  };
  tocs.forEach((element) => {
    const childs = tocs
      .filter((o) => o.parentId == element.uniqueId)
      .map((o) => o.uniqueId);
    const a = {
      index: element.uniqueId,
      name: element.fileName,
      canMove: true,
      isFolder: true,
      children: childs || [],
      data: element,
      canRename: true,
    };
    dataResult[element.uniqueId || ""] = a;
  });
  return { ...dataResult };
}
export interface TreeBookmarkProps {
  onDrop?: (
    items: TreeItem<Api.TocTabInfo>[],
    target: DraggingPosition,
    treeData: any,
    selectedItems: TreeItemIndex[]
  ) => void;
}
const TreeToc = ({ onDrop }: TreeBookmarkProps) => {
  const { tr } = useLang();
  const [tocCurrentState, setTOCState] = useRecoilState(TOCState);
  const [targetPoint, SetTargetPoint] = useState<{ x: number; y: number }>();
  const [IsShowContextMenu, setShowContextMenu] = useState({
    x: 0,
    y: 0,
    show: false,
  });
  const [IsShowAddBlankPageModal, { toggle: SetIsShowAddBlankPageModal }] =
    useBoolean(false);
  const [itemTrees, setItemTrees] = useState<any>({
    root: {
      index: "root",
      canMove: true,
      isFolder: false,
      children: [],
      canRename: true,
      data: { id: "00000000-0000-0000-0000-000000000000" },
    },
  });
  const tree = useRef<TreeRef>(null);
  useEffect(() => {
    const timout = setTimeout(() => {
      if (tree.current) {
        tree.current.expandAll();
      }
    }, 500);
    return () => clearTimeout(timout);
  }, []);
  useEffect(() => {
    const items = [];
    for (const field in itemTrees) {
      if (field != "root" && itemTrees[field].children.length > 0) {
        items.push(field);
      }
    }
  }, [itemTrees]);
  useEffect(() => {
    setItemTrees(BuildDataTree(tocCurrentState?.tocData || []));
  }, [tocCurrentState?.tocData]);

  // const getPercenOcrDone = (item: Api.TocTabInfo) => {
  //   if (item.needOcr == 0) {
  //     return 100;
  //   } else {
  //     return (item.ocrProcessed! / item.needOcr!) * 100;
  //   }
  // };
  return (
    <StyledTreeTOC
      style={{ position: "relative" }}
      onContextMenu={(e) => {
        // onContextMenu && onContextMenu(e);
        e.preventDefault();
        e.stopPropagation();
        setShowContextMenu({
          x: e.clientX,
          y: e.clientY,
          show: true,
        });
      }}
      className="treeToc "
    >
      <LXTreeView
        treeId="Ltree-TOC"
        data={itemTrees}
        canDragAndDrop={true}
        className="treeToc"
        onContextMenu={(e, item) => {
          const tocItem = tocCurrentState.tocData.find(
            (o) => (o.uniqueId || "") === item.index
          );
          setTOCState((cur) => {
            return {
              ...cur,
              tocFocusItem: tocItem || null,
            };
          });
          e.preventDefault();
          e.stopPropagation();
          setShowContextMenu({
            x: e.clientX,
            y: e.clientY,
            show: true,
          });
        }}
        onDrop={(e, ta, treeData, selectedItems) => {
          onDrop && onDrop(e, ta, treeData, selectedItems);
        }}
        onSelectedItemsChanged={(items) => {
          const tocSelecteds = tocCurrentState.tocData.filter((o) =>
            items.includes(o.uniqueId || "")
          );

          setTOCState((cur) => {
            return {
              ...cur,
              tocSelectedItem: [...tocSelecteds],
            };
          });
        }}
        columns={[
          {
            name: "filename",
            title: tr("filename"),
            flexGrow: 1,
            render: (item: TreeItem<Api.TocTabInfo>, col) => {
              return (
                <div
                  className={col.className == undefined ? "col" : col.className}
                  style={{
                    flexGrow: col.flexGrow || 0,
                    flexBasis: col.width ? col.width : "unset",
                  }}
                  onDoubleClick={() => {
                    documentService.GotoPage(
                      DataCache.docuViewareID(),
                      parseFloat(
                        item.data?.pageRangeDisplay!.split(".")[0].trim() || "0"
                      )
                    );
                  }}
                >
                  <div className="tabtoc" title={item.data.fileName || ""}>
                    {item.data.fileName || ""}
                  </div>
                </div>
              );
            },
          },
          {
            name: "stampIndex",
            title: tr("index"),
            width: 60,
            render: (item: TreeItem<Api.TocTabInfo>, col) => {
              return (
                <div
                  className={col.className == undefined ? "col" : col.className}
                  style={{
                    flexGrow: col.flexGrow || 0,
                    flexBasis: col.width ? col.width : "unset",
                    minWidth: col.width ? col.width : "unset",
                    maxWidth: col.width ? col.width : "unset",
                  }}
                  onDoubleClick={() => {
                    documentService.GotoPage(
                      DataCache.docuViewareID(),
                      parseFloat(
                        item.data?.pageRangeDisplay!.split(".")[0].trim() || "0"
                      )
                    );
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      lineHeight: "30px",
                    }}
                    title={item.data.stampIndex || ""}
                  >
                    {item.data.stampIndex}
                  </div>
                </div>
              );
            },
          },
          {
            name: "pageRangeDisplay",
            title: tr("page"),
            width: 65,
            render: (item: TreeItem<Api.TocTabInfo>, col) => {
              return (
                <div
                  className={col.className == undefined ? "col" : col.className}
                  style={{
                    flexGrow: col.flexGrow || 0,
                    flexBasis: col.width ? col.width : "unset",
                    minWidth: col.width ? col.width : "unset",
                    maxWidth: col.width ? col.width : "unset",
                  }}
                  onDoubleClick={() => {
                    documentService.GotoPage(
                      DataCache.docuViewareID(),
                      parseFloat(
                        item.data?.pageRangeDisplay!.split(".")[0].trim() || "0"
                      )
                    );
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      lineHeight: "30px",
                    }}
                    title={item.data.pageRangeDisplay || ""}
                  >
                    {item.data.pageRangeDisplay}
                  </div>
                </div>
              );
            },
          },
        ]}
      ></LXTreeView>

      {IsShowContextMenu.show && (
        <TocContextMenu
          hideMenu={() => {
            setShowContextMenu({ x: 0, y: 0, show: false });
          }}
          targetPoint={{ x: IsShowContextMenu.x, y: IsShowContextMenu.y }}
          OnAddBlankPage={SetIsShowAddBlankPageModal}
        />
      )}
      {IsShowAddBlankPageModal && (
        <AddBlankPageModal
          onClose={SetIsShowAddBlankPageModal}
          selectedTabIds={
            tocCurrentState.tocSelectedItem.map((o) => o.uniqueId) as string[]
          }
          isShow={IsShowAddBlankPageModal}
        ></AddBlankPageModal>
      )}
    </StyledTreeTOC>
  );
};
export default TreeToc;
