import {
  dialogState,
  modalContainerState,
  ocrSocketSignalRState,
  pageSelectedState,
  trackingState,
} from "./../recoil";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import { Utility } from "../config/utility";
import * as Api from "../ApiV2";
import { useRecoilState } from "recoil";
import { leftSidebarState } from "../recoil";
import { documentService } from "./document";
import { DataCache, LocalStorageKey } from "../config/constant";
import { userService } from "./userService";
import { CacheLXApp } from "../App";
export const baseAPI = `${Utility.baseUrl()}`;
const OcrApi = new Api.OcrApi();
const CheckDuplicateApi = new Api.CheckDuplicateApi();

let totalOCRPages = 0;
export const ocrSocketSignalRSService = () => {
  const [, setPageState] = useRecoilState(pageSelectedState);
  const [, setDialogState] = useRecoilState(dialogState);
  const [OcrSocketSignalR, setOcrSocketSignalRState] = useRecoilState(
    ocrSocketSignalRState
  );
  const [modalContainer, setModalContainerState] =
    useRecoilState(modalContainerState);
  const { getUser } = userService();
  const [, setTrackingState] = useRecoilState(trackingState);
  const GetOcrid = () => {
    let ocrId = localStorage.getItem(LocalStorageKey.OcrId);
    if (ocrId) {
      return ocrId;
    } else {
      ocrId = uuidv4();
      localStorage.setItem(LocalStorageKey.OcrId, ocrId);
      return ocrId;
    }
  };
  const CheckDuplicate = async () => {
    setTrackingState((cur) => {
      return { ...cur, openingLoadingState: { isOpen: true } };
    });
    CheckDuplicateApi.apiLegalxtractCheckDuplicatePost({
      isCurrentDocument: false,
      includeHideOnExtract: false,
    }).then((data) => {
      setTrackingState((cur) => {
        return { ...cur, openingLoadingState: { isOpen: false } };
      });
      if (data.data) {
        setModalContainerState((cur) => {
          return {
            ...cur,
            previewFindDuplicates: {
              CheckDuplicateResponse: data.data,
              isShow: true,
            },
          };
        });
      }
    });
  };
  const ocrDocument = (
    ocrType: "OCRCurrentPage" | "OCRCurrentTab" | "OCRAllTabs",
    OCRCurrentPageParam: {
      currentTab: Api.TabDetail;
      currentPage: number;
    } | null,
    currentTab: Api.TabDetail | null,
    tabs: Api.TabDetail[] | null,
    isOpenFile?: boolean
  ) => {
    const email = getUser()?.userDataLogin?.result?.user_info?.email;
    if (
      OcrSocketSignalR.OcrProessing &&
      email &&
      !checkNotSendMailRunOcr(ocrType, currentTab, tabs)
    ) {
      setDialogState((cur) => {
        return {
          ...cur,
          MsgDialog: `Your document is currently being processed, and once the OCR is complete, we will send an email to the address ${email}.`,
          Type: "WarningDialog",
        };
      });
      return;
    }
    // if (isOpenFile) {
    //   runOcr(ocrType, OCRCurrentPageParam, currentTab, tabs);
    //   return;
    // }
    if (checkNotSendMailRunOcr(ocrType, currentTab, tabs)) {
      runOcr(ocrType, OCRCurrentPageParam, currentTab, tabs, isOpenFile);
    } else {
      setDialogState((cur) => {
        return {
          ...cur,
          MsgDialog: `You can leave the browser window open, and you follow the progress of the OCR process. An e-mail will be send to ${email} when the process is done.  <br>
          Alternatively, you can close the browser window, and we will send an email to ${email} once the OCR process is done. The email will include a link that allows you to reopen the document with the completed OCR.`,
          Type: "AlertDialog",
          CallBackConfirmDialog: {
            action: () => {
              runOcr(
                ocrType,
                OCRCurrentPageParam,
                currentTab,
                tabs,
                isOpenFile
              );
            },
            param: null,
          },
        };
      });
    }
  };
  const checkNotSendMailRunOcr = (
    ocrType: "OCRCurrentPage" | "OCRCurrentTab" | "OCRAllTabs",
    currentTab: Api.TabDetail | null,
    tabs: Api.TabDetail[] | null
  ): boolean => {
    const email = getUser()?.userDataLogin?.result?.user_info?.email;
    if (!email) return true;
    switch (ocrType) {
      case "OCRAllTabs": {
        const totalOcr = tabs
          ?.map((o) => o.numberPageNeedOCR)
          .reduce((accumulator, currentValue) => {
            return (accumulator || 0) + (currentValue || 0);
          }, 0);
        return (
          (totalOcr || 0) <=
          (CacheLXApp.lxSetting.dontSendOCRMailPageCount || 100)
        );
      }
      case "OCRCurrentPage": {
        return true;
      }
      case "OCRCurrentTab": {
        return (
          (currentTab?.numberPageNeedOCR || 0) <=
          (CacheLXApp.lxSetting.dontSendOCRMailPageCount || 100)
        );
      }
    }
  };
  const runOcr = (
    ocrType: "OCRCurrentPage" | "OCRCurrentTab" | "OCRAllTabs",
    OCRCurrentPageParam: {
      currentTab: Api.TabDetail;
      currentPage: number;
    } | null,
    currentTab: Api.TabDetail | null,
    tabs: Api.TabDetail[] | null,
    isOpenFile?: boolean
  ) => {
    setTrackingState((cur) => {
      return { ...cur, openingLoadingState: { isOpen: true } };
    });
    switch (ocrType) {
      case "OCRAllTabs": {
        if (tabs) handleOCRAllTabs(tabs, isOpenFile);
        break;
      }
      case "OCRCurrentPage": {
        if (OCRCurrentPageParam)
          handleOCRCurrentPage(
            OCRCurrentPageParam.currentTab,
            OCRCurrentPageParam.currentPage,
            isOpenFile
          );
        break;
      }
      case "OCRCurrentTab": {
        if (currentTab) handleOCRCurrentTab(currentTab, isOpenFile);
        break;
      }
    }
  };
  const handleOCRCurrentPage = (
    currentTab: Api.TabDetail,
    currentPage: number,
    isOpenFile?: boolean
  ) => {
    if (OcrSocketSignalR.ocrProgress > 0.0001) return;
    setOcrSocketSignalRState((cur) => {
      return {
        ...cur,
        onAfterOCRDone: null,
        cancelOCR: false,
        isOpenFile: isOpenFile || false,
        ocrProgress: 0.0001,
        PreparingPage: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
        OCRing: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
        ReadingFile: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
      };
    });
    const ocrId = GetOcrid();
    totalOCRPages = 1;

    setPageState((cur) => {
      CacheLXApp.PageState = {
        ...CacheLXApp.PageState!,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
      return {
        ...cur,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
    });
    setTrackingState((cur) => {
      return { ...cur, openingLoadingState: { isOpen: true, label: "" } };
    });
    OcrApi.apiLegalxtractOcrProcessPost({
      ocrId: ocrId,
      ocrMode: Api.OcrMode.NUMBER_1,
      tabId: currentTab?.uniqueId ? [currentTab?.uniqueId] : [],
      email: getUser()?.userDataLogin?.result?.user_info?.email,
      pages: [currentPage],
    }).then((res) => {
      setTrackingState((cur) => {
        return { ...cur, openingLoadingState: { isOpen: false, label: "" } };
      });
      if (res.data && res.data.totalPageOcr === 0) {
        setOcrSocketSignalRState((cur) => {
          return {
            ...cur,
            ocrProgress: 0,
            isOpenFile: false,
          };
        });
      }
    });
  };

  const handleOCRCurrentTab = (
    currentTab: Api.TabDetail,
    isOpenFile?: boolean
  ) => {
    if (currentTab.numberPageNeedOCR == 0 || OcrSocketSignalR.ocrProgress > 0.0001)
      return;
    setOcrSocketSignalRState((cur) => {
      return {
        ...cur,
        onAfterOCRDone: null,
        cancelOCR: false,
        ocrProgress: 0.0001,
        isOpenFile: isOpenFile || false,
        PreparingPage: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
        OCRing: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
        ReadingFile: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
      };
    });
    const ocrId = GetOcrid();
    setPageState((cur) => {
      CacheLXApp.PageState = {
        ...CacheLXApp.PageState!,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
      return {
        ...cur,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
    });

    setTrackingState((cur) => {
      return { ...cur, openingLoadingState: { isOpen: true, label: "" } };
    });
    OcrApi.apiLegalxtractOcrProcessPost({
      ocrId: ocrId,
      ocrMode: Api.OcrMode.NUMBER_1,
      tabId: currentTab?.uniqueId ? [currentTab?.uniqueId] : [],
      email: getUser()?.userDataLogin?.result?.user_info?.email,
      pages: [],
    }).then((res) => {
      setTrackingState((cur) => {
        return { ...cur, openingLoadingState: { isOpen: false, label: "" } };
      });
      if (res.data && res.data.totalPageOcr === 0) {
        setOcrSocketSignalRState((cur) => {
          return {
            ...cur,
            ocrProgress: 0,
            isOpenFile: false,
          };
        });
      }
    });
  };

  const handleOCRAllTabs = (tabs: Api.TabDetail[], isOpenFile?: boolean) => {
    if (OcrSocketSignalR.ocrProgress > 0.0001) return;
    const tabIds = [] as string[];
    let totalPages = 0;
    tabs.forEach((tab) => {
      totalPages += tab.numberPageNeedOCR!;
      tabIds.push(tab.uniqueId || "");
    });
    if (totalPages === 0) return;
    setOcrSocketSignalRState((cur) => {
      return {
        ...cur,
        onAfterOCRDone: null,
        cancelOCR: false,
        isOpenFile: isOpenFile || false,
        ocrProgress: 0.0001,
        PreparingPage: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
        OCRing: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
        ReadingFile: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
      };
    });

    totalOCRPages = totalPages;
    setPageState((cur) => {
      CacheLXApp.PageState = {
        ...CacheLXApp.PageState!,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
      return {
        ...cur,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
    });
    const ocrId = GetOcrid();
    setTrackingState((cur) => {
      return { ...cur, openingLoadingState: { isOpen: true, label: "" } };
    });
    OcrApi.apiLegalxtractOcrProcessPost({
      ocrId: ocrId,
      ocrMode: Api.OcrMode.NUMBER_1,
      tabId: tabIds,
      email: getUser()?.userDataLogin?.result?.user_info?.email,
      pages: [],
    }).then((res) => {
      setTrackingState((cur) => {
        return { ...cur, openingLoadingState: { isOpen: false, label: "" } };
      });
      if (res.data && res.data.totalPageOcr === 0) {
        setOcrSocketSignalRState((cur) => {
          // if (CacheLXApp.lxSetting.checkDuplicate && cur.isOpenFile) {
          //   setTimeout(() => {
          //     CheckDuplicate();
          //   }, 200);
          // }
          return {
            ...cur,
            ocrProgress: 0,
            isOpenFile: false,
          };
        });
      }
    });
  };
  const RunOCRAllTabsNotSendMail = (
    tabs: Api.TabDetail[],
    onAfterOCRDone: (() => void) | null
  ) => {
    if (OcrSocketSignalR.ocrProgress > 0.0001) return;
    const tabIds = [] as string[];
    let totalPages = 0;
    tabs.forEach((tab) => {
      totalPages += tab.numberPageNeedOCR!;
      tabIds.push(tab.uniqueId || "");
    });
    if (totalPages === 0) {
      onAfterOCRDone && onAfterOCRDone();
      return;
    }
    setOcrSocketSignalRState((cur) => {
      return {
        ...cur,
        cancelOCR: false,
        isOpenFile: false,
        onAfterOCRDone: onAfterOCRDone,
        ocrProgress: 0.0001,
        PreparingPage: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
        OCRing: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
        ReadingFile: {
          OCRTotalPages: 0,
          OCRingPages: 0,
        },
      };
    });

    totalOCRPages = totalPages;
    setPageState((cur) => {
      CacheLXApp.PageState = {
        ...CacheLXApp.PageState!,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
      return {
        ...cur,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
    });
    const ocrId = GetOcrid();
    setTrackingState((cur) => {
      return { ...cur, openingLoadingState: { isOpen: true, label: "" } };
    });
    OcrApi.apiLegalxtractOcrProcessPost({
      ocrId: ocrId,
      ocrMode: Api.OcrMode.NUMBER_1,
      tabId: tabIds,
      email: "",
      pages: [],
    }).then((res) => {
      setTimeout(() => {
        setTrackingState((cur) => {
          return { ...cur, openingLoadingState: { isOpen: false, label: "" } };
        });
      }, 200);
      if (res.data && res.data.totalPageOcr === 0) {
        onAfterOCRDone && onAfterOCRDone();
        setOcrSocketSignalRState((cur) => {
          return {
            ...cur,
            ocrProgress: 0,
            isOpenFile: false,
          };
        });
      }
    });
  };
  const handleCancelOCR = async (updateTabs?: any) => {
    const ocrId = GetOcrid();
    const status = await OcrApi.apiLegalxtractCancelOcrPost(ocrId || "");
    setOcrSocketSignalRState((cur) => {
      return {
        ...cur,
        cancelOCR: true,
      };
    });
    documentService.RefreshPage(null, DataCache.docuViewareID());
  };
  const ReadFileOCrDone = async (ocrid: string) => {
    let Id = ocrid;
    if (!ocrid) {
      Id = GetOcrid();
    }
    OcrApi.apiLegalxtractReadFileOCrDonePost({
      ocrId: Id || "",
    });
  };

  return {
    ocrDocument,
    RunOCRAllTabsNotSendMail,
    handleCancelOCR,
    ReadFileOCrDone,
    GetOcrid,
  };
};
