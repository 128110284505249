import Cookies from "js-cookie";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import * as Api from "./ApiV2";
import globalAxiosConfig from "./ApiV2/axiosConfig";
import "./App.css";
import { StyledContainer, StyledLayout } from "./assets/style/app";
import { JobCheckCookeis } from "./components/cronjob/JobCheckCookeis";
import {
  CompanyCode,
  Constants,
  DataCache,
  LocalStorageKey,
} from "./config/constant";
import LoadingFullscreen from "./control/loading/loading-fullscreen";
import { GoToNova } from "./modal/SaveFileModal";
import {
  Annotation,
  IMouseState,
  IPageState,
  IWordState,
  dialogState,
  leftSidebarState,
  trackingState,
} from "./recoil";
import { DocuVieware } from "./recoil/docuviewareState";
import { userService } from "./service/userService";
import { axiosInstance } from "./config/axios-config";
import { useLang } from "./i18n/useLang";
import { TabContextMenuProps } from "./components/layouts/menuContext/mainMenuContext";
import { useNavigate } from "react-router-dom";
import { getParameterByName } from "./utils/helpers";
import SplitPane, { Pane } from "split-pane-react";
import "split-pane-react/esm/themes/default.css";
import { documentService } from "./service/document";
import { AppHelper } from "./utils/AppHelper";
import { defaultStampData } from "./recoil/topmenuStripState";
import { LxSettingClient } from "./modal/settingmodal";
const MenuTrip = React.lazy(() => import("./components/topMenu/topMenuTrip"));
const LeftSidebar = React.lazy(
  () => import("./components/left-sidebar/left-sidebar-container")
);
const MenusIcon = React.lazy(() => import("./components/topMenu/menusIcon"));
const HomePage = React.lazy(() => import("./containers/home-page"));
const OpeningApi = new Api.OpeningApi();
const SettingApi = new Api.SettingApi();
const TestApi = new Api.TestApi();
let timeout: any;
let ischeckFosCusTab = false;
export const CacheLXApp = {
  CurrentZoom: 0 as number,
  FileInputAccept: "",
  FileTypeSupport: null as any | null,
  AnnotState: null as Annotation | null,
  MainMenuContext: null as TabContextMenuProps | null,
  DocuviewareMain: null as DocuVieware | null,
  PageState: null as IPageState | null,
  WordCurrentState: null as IWordState | null,
  MouseModeState: null as IMouseState | null,
  LineBorderWidth: {
    borderEdit: 0.02,
    colorEdit: "#323130",
    borderAdd: 0.02,
    colorAdd: "#323130",
  } as {
    borderEdit?: number;
    colorEdit?: string;
    borderAdd?: number;
    colorAdd?: string;
  } | null,
  LicenseInfo: {} as Api.LicenseInfoWeb,
  KeyCodeOfKeyDown: null as number | null,
  Thumbnails: [] as Api.ThumbnailResult[],
  FocusComponent: "" as string,
  FocusWord: null as Api.LxWordResponse | null, // di chuyển focus trên view
  DataMouseMoveInView: null as any | null,
  pageDetails: [] as Api.WordInPage[],
  lxSetting: {} as LxSettingClient,
  ApprovalStampInfoParam: null as Api.ApprovalStampInfo | null,
  clickOutDocument: false,
};
const ModalContainer = React.lazy(
  () => import("./modal/container/modal-container")
);
export const App = () => {
  const { tr } = useLang();
  const { getUser } = userService();
  const { runJob } = JobCheckCookeis();
  const [openModal, setOpenModalUser] = useState("");
  const [leftSidebar] = useRecoilState(leftSidebarState);
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [tracking, setTrackingState] = useRecoilState(trackingState);
  const navigate = useNavigate();
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    globalAxiosConfig();
    runJob();
    CheckUserLogin();
  }, []);
  useEffect(() => {
    const el = document.getElementsByClassName("layout-pane")[0];
    if (el) {
      el.setAttribute("style", `width: ${leftSidebar.SecondaryInitialSize}px;`);
    }
    setSizes([leftSidebar.SecondaryInitialSize, "auto"]);
  }, [leftSidebar.SecondaryInitialSize]);
  const [sizes, setSizes] = useState([425, "auto"]);
  const CheckUserLogin = async () => {
    const user = getUser();
    if (user) {
      if (!DataCache.REACT_APP_CompanyCode) {
        const res = await axiosInstance.post(
          DataCache.REACT_APP_API_ENDPOINT + "/api/legalxtract/CheckUserStatus",
          {
            token: user.userDataLogin?.result?.access_token,
          }
        );
        setDataLoaded(true);
        if (!res.data.ErrorMessage) {
          const a = res.data.result as Api.UserStatusResult;
          if (!a || a.status != 1) {
            window.location.href = "/login";
          } else {
            let msg = "";
            if (a.licenseData?.mode == "invalid") {
              if (a.licenseData.description == "key expired") {
                msg = "Your time to use the software is over.";
              } else {
                msg = "You no longer have the right to use the software";
              }
            }
            if (msg) {
              setDialogState({
                ...dialog,
                MsgDialog: msg,
                CallBackConfirmDialog: {
                  action: () => {
                    window.location.href = "/login";
                  },
                  param: null,
                },
                Type: "WarningDialog",
              });
              setTrackingState({
                openingLoadingState: { isOpen: false, label: "" },
                searchLoadingState: { isOpen: false, label: "" },
                showMSGExpireCookie: false,
              });
              return;
            }
          }
        } else {
          setDialogState({
            ...dialog,
            MsgDialog: res.data.ErrorMessage,
            CallBackConfirmDialog: {
              action: () => {
                window.location.href = "/login";
              },
              param: null,
            },
            Type: "WarningDialog",
          });
          setTrackingState({
            openingLoadingState: { isOpen: false, label: "" },
            searchLoadingState: { isOpen: false, label: "" },
            showMSGExpireCookie: false,
          });
          return;
        }
      }

      SettingApi.apiLegalxtractFileTypeSupportGet().then((res) => {
        const data = res.data;
        let acceptFileExtensions: string[] = [];
        Object.keys(data).forEach((key: string) => {
          acceptFileExtensions = acceptFileExtensions.concat(
            data[key].split(";").map((ext: any) => `.${ext.toLowerCase()}`)
          );
        });
        CacheLXApp.FileTypeSupport = data;
        CacheLXApp.FileInputAccept =
          acceptFileExtensions.join(",") + ",.xtract" || "";
      });
      CacheLXApp.lxSetting = AppHelper.ConfigSettings();
      document.addEventListener("visibilitychange", function () {
        if (!document.hidden && !window.location.href.includes("/login")) {
          if (ischeckFosCusTab == false) {
            ischeckFosCusTab = true;
            TestApi.apiLegalxtractDocuViewareHealthCheckGet().then(() => {
              ischeckFosCusTab = false;
            });
          }
        }
      });
      const msg = tr("yourSessionHasExpiredGoToNOVA");
      const tokenThirdParty = Cookies.get(Constants.TokenThirdParty);
      if (
        !tokenThirdParty &&
        DataCache.REACT_APP_CompanyCode?.toLowerCase() == CompanyCode.Nova
      ) {
        setTrackingState({ ...tracking, showMSGExpireCookie: true });
        setDialogState({
          ...dialog,
          MsgDialog: msg,
          CallBackConfirmDialog: {
            action: () => {
              setTrackingState({ ...tracking, showMSGExpireCookie: false });
              GoToNova();
            },
            param: null,
          },
          Type: "AlertDialog",
        });
      }
      const docId = getParameterByName("docId", window.location.href);
      if (
        DataCache.REACT_APP_CompanyCode?.toLowerCase() == CompanyCode.lc &&
        docId
      ) {
        navigate("/");
      }
    }
    if (DataCache.REACT_APP_CompanyCode) {
      setDataLoaded(true);
    }
  };
  if (dataLoaded === false) {
    return <></>;
  }
  return (
    <>
      <LoadingFullscreen />
      <div id="loader" className="loader-container" style={{ display: "none" }}>
        <div className="loader"></div>
      </div>
      <StyledLayout>
        <Suspense
          fallback={
            <>
              <div className="loader-content">
                <div className="loader-fallback"></div>
                <h2>{tr("loadingComponents")}</h2>
              </div>
            </>
          }
        >
          {<MenuTrip />}
          {<MenusIcon />}
          <StyledContainer widthLeft={leftSidebar.SecondaryInitialSize}>
            <div style={{ height: "100%", width: "100%" }}>
              <SplitPane
                split="vertical"
                sizes={sizes}
                onChange={(sizes) => {
                  setSizes(sizes);
                  documentService.centerScroll();
                  if (timeout) {
                    clearTimeout(timeout);
                  }
                  timeout = setTimeout(() => {
                    DocuViewareAPI.UpdateLayout(DataCache.docuViewareID());
                    timeout = null;
                  }, 150);
                }}
                sashRender={(sashProps) => {
                  return (
                    <div
                      style={{
                        backgroundColor: "red",
                        width: 10,
                        cursor: "col-resize",
                      }}
                    />
                  );
                }}
              >
                <Pane minSize={45} maxSize="50%">
                  {<LeftSidebar />}
                </Pane>
                {
                  <HomePage
                    openModal={openModal}
                    setOpenModal={() => {
                      setOpenModalUser("");
                    }}
                  />
                }
              </SplitPane>
            </div>
          </StyledContainer>
        </Suspense>
      </StyledLayout>
      <ModalContainer></ModalContainer>
    </>
  );
};
