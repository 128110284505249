import React, { useEffect, useState } from "react";
import {
  Callout,
  ColorPicker,
  IColor,
  IColorPickerStyles,
} from "@fluentui/react";

const colorPickerStyles: Partial<IColorPickerStyles> = {
  panel: {
    padding: "10px",
  },
};

type ColorPickerComponentProps = {
  color: IColor;
  colorDivElement: React.MutableRefObject<null>;
  onCalloutDismiss: (newColor: IColor) => void;
};

export default (props: ColorPickerComponentProps) => {
  const [color, setColor] = useState<IColor>(props.color);

  const onChangeColor = (
    ev: React.SyntheticEvent<HTMLElement>,
    newColorObj: IColor
  ) => setColor(newColorObj);
  const onColorDismiss = (e: MouseEvent) => {
    props.onCalloutDismiss(color);
  };
  useEffect(() => {
    document.addEventListener("mousedown", onColorDismiss);
    return () => {
      document.removeEventListener("mousedown", onColorDismiss);
    };
  }, []);
  return (
    <Callout
      ariaLabelledBy="textColor"
      ariaDescribedBy="textColorChange"
      role="alertdialog"
      gapSpace={0}
      target={props.colorDivElement.current}
      coverTarget={true}
      isBeakVisible={false}
      onDismiss={() => props.onCalloutDismiss(color)}
      setInitialFocus={true}
    >
      <ColorPicker
        color={color}
        showPreview={true}
        styles={colorPickerStyles}
        onChange={onChangeColor}
      />
    </Callout>
  );
};
