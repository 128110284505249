import * as React from "react";
import { useLang } from "../../../i18n/useLang";
import {
  Button,
  CheckboxVisibility,
  DetailsList,
  Dropdown,
  IColumn,
  IDetailsList,
  Label,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import * as Api from "../../../ApiV2";
import { historyService } from "../../../service/historyService";
import { DataCache } from "../../../config/constant";
import FileSaver from "file-saver";
import { StyleList } from "../../../assets/style/left-sidebar/history";
import { useRecoilState } from "recoil";
import {
  historyState,
  IPageState,
  ITabState,
  pageSelectedState,
  tabState,
} from "../../../recoil";
import {
  DocuVieware,
  docuviewareMainState,
} from "../../../recoil/docuviewareState";
import { CacheLXApp } from "../../../App";
const HistoryApi = new Api.HistoryApi();
export default () => {
  const [docuviewareMain, setDocuviewareMain] =
    useRecoilState<DocuVieware>(docuviewareMainState);
  const [historyCurrentState, setHistoryState] = useRecoilState(historyState);
  const [pageState, setPageState] =
    useRecoilState<IPageState>(pageSelectedState);
  const _root = React.createRef<IDetailsList>();
  const _columns: IColumn[] = [
    { key: "text", name: "", fieldName: "text", minWidth: 140 },
    {
      key: "dateTime",
      name: "",
      fieldName: "dateTime",
      minWidth: 120,
      maxWidth: 120,
    },
    {
      key: "pageNo",
      name: "",
      fieldName: "pageNo",
      minWidth: 30,
      maxWidth: 30,
    },
  ];
  const { tr } = useLang();
  const { getHistoryView } = historyService();
  React.useEffect(() => {
    const obj = docuviewareMain?.DocuviewareInit?.tabDetails?.find(
      (o) => o.uniqueId == historyCurrentState.tabId
    );
    if (!obj) {
      setHistoryState({
        ...historyCurrentState,
        tabId: "00000000-0000-0000-0000-000000000000",
      });
    }
  }, []);

  React.useEffect(() => {
    const uniqueId = historyCurrentState.tabId;
    if (!uniqueId) {
      setHistoryState({ ...historyCurrentState, HistoryView: null });
      return;
    }
    getHistoryView(uniqueId);
  }, [historyCurrentState.tabId]);

  const getGroups = (): any[] => {
    if ((docuviewareMain?.DocuviewareInit?.tabDetails?.length || 0) == 0) {
      return [];
    }
    if (historyCurrentState.HistoryView?.groups == undefined) return [];
    return historyCurrentState.HistoryView?.groups
      ? historyCurrentState.HistoryView?.groups.map((o) => {
          return {
            key: o.text,
            name: o.text,
            startIndex: o.startIndex,
            count: o.count,
            level: o.level,
          };
        })
      : [];
  };
  const getTabs = (): any[] => {
    let arrOption =
      docuviewareMain?.DocuviewareInit?.tabDetails
        ?.filter((i) => i.tabFileType == Api.TabFileType.NUMBER_0)
        .map((o) => {
          return { key: o.uniqueId, text: o.fileName };
        }) || [];
    arrOption = [
      { key: "00000000-0000-0000-0000-000000000000", text: tr("viewAll") },
      ...arrOption,
    ];
    return arrOption;
  };
  const exportExcel = async () => {
    if (!docuviewareMain?.DocuviewareInit?.htmlString) return;
    const tabDetail = getTabs().find(
      (o) => o.key == historyCurrentState.tabId
    ) || { text: "" };
    const response = (
      await HistoryApi.apiLegalxtractExportExcelGet({ responseType: "blob" })
    ).data;
    FileSaver(response, tabDetail.text + ".xlsx" || "Log file.xlsx");
  };

  const getItems = () => {
    if ((docuviewareMain?.DocuviewareInit?.tabDetails?.length || 0) == 0) {
      return [];
    }
    return historyCurrentState.HistoryView?.items
      ? historyCurrentState.HistoryView.items
      : [];
  };

  return (
    <StyleList>
      <Stack
        tokens={{ childrenGap: 15 }}
        style={{ height: "100%" }}
        verticalAlign="center"
      >
        <div className="header-left-panel">{tr("Log")}</div>
        <div style={{ display: "flex" }}>
          <Label style={{ marginRight: "10px" }}>{tr("logFileFor")}</Label>
          <Dropdown
            style={{ flex: 1 }}
            defaultSelectedKey={historyCurrentState.tabId}
            options={getTabs()}
            onChange={(e, option, index) => {
              setHistoryState({
                ...historyCurrentState,
                tabId: option?.key.toString() || "",
              });
            }}
          />
          &ensp;
          <PrimaryButton
            text={tr("export")}
            onClick={() => {
              exportExcel();
            }}
          ></PrimaryButton>
        </div>
        <div className="log-list">
          <DetailsList
            componentRef={_root}
            // className={(HistoryView?.items || []).length > 0 ? "min-width" : ""}
            checkboxVisibility={CheckboxVisibility.hidden}
            items={getItems()}
            groups={getGroups()}
            isHeaderVisible={false}
            columns={_columns}
            onRenderItemColumn={(item, index, col) => {
              if (!col) return "";
              let color = "transparent";
              let fieldName = "";
              if (col.fieldName == "text") {
                color = item.color;
              }
              if (col.fieldName) fieldName = col.fieldName;
              return (
                <span style={{ background: `${color}` }} className="text">
                  {item[fieldName]}
                </span>
              );
            }}
            groupProps={{
              showEmptyGroups: true,
            }}
            onActiveItemChanged={(item, index, ev) => {
              if (item) {
                const tabdetail =
                  docuviewareMain?.DocuviewareInit?.tabDetails?.find(
                    (i) => i.uniqueId == historyCurrentState.tabId
                  );
                setPageState((cur) => {
                  CacheLXApp.PageState = {
                    ...CacheLXApp.PageState!,
                    currentPage:
                      (tabdetail?.startPage || 0) +
                      parseInt(item.pageNo.replace("P:", "")) -
                      1,
                  };
                  return {
                    ...cur,
                    currentPage:
                      (tabdetail?.startPage || 0) +
                      parseInt(item.pageNo.replace("P:", "")) -
                      1,
                  };
                });
                DocuViewareAPI.SelectPage(
                  DataCache.docuViewareID(),
                  (tabdetail?.startPage || 0) +
                    parseInt(item.pageNo.replace("P:", "")) -
                    1
                );
              }
            }}
            compact={false}
          />
        </div>
      </Stack>
    </StyleList>
  );
};
