import { ContextualMenu, IContextualMenuItem } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import * as Api from "../../../ApiV2";
import { DataCache } from "../../../config/constant";
import { useLang } from "../../../i18n/useLang";
import {
  dialogState,
  docuviewareMainState,
  pageSelectedState,
  tabState,
  thumbnailState,
  trackingState,
} from "../../../recoil";
import { thumbnailService } from "../../../service";
import { CacheLXApp } from "../../../App";
import { number } from "yup";
import { documentService } from "../../../service/document";
const ThumbnailApi = new Api.ThumbnailApi();
const RotateApi = new Api.RotateApi();
const DocuViewareApi = new Api.DocuViewareApi();
const DeskewApi = new Api.DeskewApi();
const PageApi = new Api.PageApi();
const JoinDocumentApi = new Api.JoinDocumentApi();
export interface ThumbnailContextMenuProps {
  isShow: boolean;
  viewerPageNo: number;
  targetPoint: any;
  thumbnailStatus?: Api.HiddenEnum | null;
  canSplitOnPublish: boolean;
}
export const ThumbnailContextMenu = ({
  param,
  hideMenu,
  handleSplitDocument,
}: {
  param: ThumbnailContextMenuProps;
  hideMenu: () => void;
  handleSplitDocument: () => void;
}) => {
  const [thumbnailCurrentState, setThumbnailState] =
    useRecoilState(thumbnailState);
  const [docuviewareMain, setDocuviewareMain] =
    useRecoilState(docuviewareMainState);
  const [pageState, setPageState] = useRecoilState(pageSelectedState);
  const [tracking, setTrackingState] = useRecoilState(trackingState);
  const [tabstate] = useRecoilState(tabState);
  const [dialog, setDialogState] = useRecoilState(dialogState);

  const { refreshThumbnail } = thumbnailService();
  const { tr } = useLang();
  useEffect(() => {
    document.addEventListener("mousedown", hideMenu);
    return () => {
      document.removeEventListener("mousedown", hideMenu);
    };
  }, []);
  const onShowThumbnail = async (event: any) => {
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: true,
        label: ``,
      },
    });
    event.preventDefault();
    setPageState((cur) => {
      CacheLXApp.PageState = {
        ...CacheLXApp.PageState!,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
      return {
        ...cur,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
    });
    const res = await ThumbnailApi.apiLegalxtractShowHidePagePost({
      status: Api.HiddenEnum.NUMBER_0,
      pages: thumbnailCurrentState.selectPages,
    });
    if (res.data) {
      setDocuviewareMain((cur) => {
        return { ...cur, DocuviewareInit: res.data };
      });
    }
    refreshThumbnail(
      thumbnailCurrentState.fromPage,
      thumbnailCurrentState.toPage
    );
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: false,
        label: ``,
      },
    });
  };

  const onHideThumbnail = async (event: any, status: Api.HiddenEnum | null) => {
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: true,
        label: ``,
      },
    });
    event.preventDefault();
    if (status) {
      setPageState((cur) => {
        CacheLXApp.PageState = {
          ...CacheLXApp.PageState!,
          oldCurrentPage: cur.currentPage,
          oldCurrentPageId: cur.PageObj?.id || "",
        };
        return {
          ...cur,
          oldCurrentPage: cur.currentPage,
          oldCurrentPageId: cur.PageObj?.id || "",
        };
      });
      const res = await ThumbnailApi.apiLegalxtractShowHidePagePost({
        status: status,
        pages: thumbnailCurrentState.selectPages,
      });
      if (res.data) {
        setDocuviewareMain((cur) => {
          return { ...cur, DocuviewareInit: res.data };
        });
      }
      setTrackingState({
        ...tracking,
        openingLoadingState: {
          ...tracking.openingLoadingState,
          isOpen: false,
          label: ``,
        },
      });
      refreshThumbnail(
        thumbnailCurrentState.fromPage,
        thumbnailCurrentState.toPage
      );
    }
  };
  const onSetSplitOnExtract = async (event: any, status: boolean) => {
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: true,
        label: ``,
      },
    });
    event.preventDefault();
    const thumbnailCtx = CacheLXApp.Thumbnails;
    const arr = (thumbnailCtx || []).map((item: any) => {
      item.imageBases = item.imageBases?.map((image: Api.ImageDetail) =>
        thumbnailCurrentState.selectPages.indexOf(image.pageNo || 0) >= 0
          ? { ...image, splitOnExtract: status || false }
          : image
      );
      return { ...item };
    });
    setThumbnailState({
      ...thumbnailCurrentState,
    });
    CacheLXApp.Thumbnails = arr;
    await ThumbnailApi.apiLegalxtractSetSplitOnExtractPost({
      splitOnExtract: status,
      pages: thumbnailCurrentState.selectPages,
    });
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: false,
        label: ``,
      },
    });
    let imageBases: Api.ImageDetail[] = [];
    arr.forEach((o) => {
      imageBases = [...imageBases, ...(o.imageBases || [])];
    });
    const fixedSplitInPages = imageBases
      .filter((o) => o.splitOnExtract == true)
      .map((o) => o.xtractPageNo)
      .join(",");
    setDocuviewareMain({
      ...docuviewareMain,
      DocuviewareInit: {
        ...docuviewareMain.DocuviewareInit,
        fixedSplitInPages: fixedSplitInPages,
      },
    });
  };
  const selectAllPages = () => {
    setThumbnailState({
      ...thumbnailCurrentState,
      selectPages:
        (
          docuviewareMain.DocuviewareInit?.pages?.filter(
            (o) => o.tabFileType == Api.TabFileType.NUMBER_0
          ) || []
        ).map((o) => o.viewerPageNo || 0) || [],
    });
  };
  const selectAllTextPages = () => {
    documentService.highLightPage({
      viewerPageNos: thumbnailCurrentState.selectPages,
      mode: 1,
    }, () => {
      refreshThumbnail(
        thumbnailCurrentState.fromPage,
        thumbnailCurrentState.toPage
      );
    });
  };
  const RotateLeft = async () => {
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: true,
        label: ``,
      },
    });
    setPageState((cur) => {
      CacheLXApp.PageState = {
        ...CacheLXApp.PageState!,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
      return {
        ...cur,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
    });
    await RotateApi.apiLegalxtractRotateLeftPost({
      viewerPageNos: thumbnailCurrentState.selectPages,
    });
    DocuViewareApi.apiLegalxtractGetDocuviewareControlGet().then((res) => {
      const data = res.data;
      if (data && data.tabDetails != undefined && data.tabDetails?.length > 0) {
        setDocuviewareMain({
          ...docuviewareMain,
          DocuviewareInit: {
            ...docuviewareMain.DocuviewareInit,
            htmlString: data.htmlString,
          },
          isRefeshDocument: true,
        });
        refreshThumbnail(
          thumbnailCurrentState.fromPage,
          thumbnailCurrentState.toPage
        );
        DocuViewareAPI.SelectPage(
          DataCache.docuViewareID(),
          pageState.currentPage
        );
      }
      setTrackingState({
        ...tracking,
        openingLoadingState: {
          ...tracking.openingLoadingState,
          isOpen: false,
          label: ``,
        },
      });
    });
  };
  const RotateRight = async () => {
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: true,
        label: ``,
      },
    });
    setPageState((cur) => {
      CacheLXApp.PageState = {
        ...CacheLXApp.PageState!,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
      return {
        ...cur,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
    });
    await RotateApi.apiLegalxtractRotateRightPost({
      viewerPageNos: thumbnailCurrentState.selectPages,
    });
    DocuViewareApi.apiLegalxtractGetDocuviewareControlGet().then((res) => {
      const data = res.data;
      if (data && data.tabDetails != undefined && data.tabDetails?.length > 0) {
        // DocuViewareAPI.SelectPage(DataCache.docuViewareID(), 1);
        setDocuviewareMain({
          ...docuviewareMain,
          DocuviewareInit: {
            ...docuviewareMain.DocuviewareInit,
            htmlString: data.htmlString,
          },
          isRefeshDocument: true,
        });
        refreshThumbnail(
          thumbnailCurrentState.fromPage,
          thumbnailCurrentState.toPage
        );
        DocuViewareAPI.SelectPage(
          DataCache.docuViewareID(),
          pageState.currentPage
        );
      }
      setTrackingState({
        ...tracking,
        openingLoadingState: {
          ...tracking.openingLoadingState,
          isOpen: false,
          label: ``,
        },
      });
    });
  };
  const onDeskew = (event: any) => {
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: true,
        label: ``,
      },
    });
    DeskewApi.apiLegalxtractDeskewPost(thumbnailCurrentState.selectPages).then(
      async () => {
        const dataDocControl = (
          await DocuViewareApi.apiLegalxtractInitDocuViewareControlGet()
        ).data;
        setPageState((cur) => {
          CacheLXApp.PageState = {
            ...cur,
            oldCurrentPage: cur.currentPage,
            oldCurrentPageId: cur.PageObj?.id || "",
          };
          return {
            ...cur,
            oldCurrentPage: cur.currentPage,
            oldCurrentPageId: cur.PageObj?.id || "",
          };
        });
        setTimeout(() => {
          refreshThumbnail(
            thumbnailCurrentState.fromPage,
            thumbnailCurrentState.toPage
          );
          setDocuviewareMain({
            ...docuviewareMain,
            isRefeshDocument: true,
            DocuviewareInit: dataDocControl,
          });
          setTrackingState({
            ...tracking,
            openingLoadingState: {
              ...tracking.openingLoadingState,
              isOpen: false,
              label: ``,
            },
          });
        }, 500);
      }
    );
  };
  const handleDeletePages = (event: any) => {
    setDialogState({
      ...dialog,
      MsgDialog: tr("doYouWantToDelete"),
      CallBackConfirmDialog: {
        action: () => {
          setPageState((cur) => {
            CacheLXApp.PageState = {
              ...cur,
              oldCurrentPage: cur.currentPage,
              oldCurrentPageId: cur.PageObj?.id || "",
            };
            return {
              ...cur,
              oldCurrentPage: cur.currentPage,
              oldCurrentPageId: cur.PageObj?.id || "",
            };
          });
          PageApi.apiLegalxtractDeletePagesPost({
            pageNos: thumbnailCurrentState.selectPages,
          }).then((data) => {
            DataCache.StatusLoadAllThumbnail = true;
            const fromPage = thumbnailCurrentState.fromPage;
            let toPage = thumbnailCurrentState.toPage;
            if (
              pageState.currentPage ==
              CacheLXApp.DocuviewareMain?.DocuviewareInit?.totalPage
            ) {
              toPage = pageState.currentPage - 1;
            }
            refreshThumbnail(fromPage - 1, toPage);

            setDocuviewareMain((curr) => {
              return {
                ...curr,
                isRefeshDocument: true,
                DocuviewareInit: data.data,
              };
            });
          });
        },
        param: null,
      },
      Type: "ConfirmationDialog",
    });
  };
  const JoinDocuments = async (tabIds: string[]) => {
    setPageState((cur) => {
      CacheLXApp.PageState = {
        ...CacheLXApp.PageState!,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
      return {
        ...cur,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
    });
    const res = await JoinDocumentApi.apiLegalxtractJoinDocumentsPost({
      tabIds,
    });
    if (!res.data) return;
    setDocuviewareMain((cur) => {
      return {
        ...cur,
        DocuviewareInit: res.data.docuviewareInit || null,
      };
    });
    refreshThumbnail(
      thumbnailCurrentState.fromPage,
      thumbnailCurrentState.toPage
    ).then(() => {});
  };
  const isConsecutiveArray = (arr: number[]): boolean => {
    {
      if (arr.length <= 1)
          return true;

      // Sắp xếp mảng
      arr.sort((a, b) => a - b);

      // Kiểm tra chênh lệch giữa các phần tử liền kề
      for (let i = 1; i < arr.length; i++)
      {
          if (arr[i] - arr[i - 1] != 1)
          {
              return false;
          }
      }

      return true;
  }
  };
  let items = [
    {
      key: "show",
      name: tr("show"),
      disabled: param.thumbnailStatus == Api.HiddenEnum.NUMBER_0,
      onClick: (ev) => {
        hideMenu();
        onShowThumbnail(ev);
      },
    },
    {
      key: "hide",
      name: tr("hide"),
      disabled: param.thumbnailStatus == Api.HiddenEnum.NUMBER_1,
      onClick: (ev) => {
        hideMenu();
        onHideThumbnail(ev, Api.HiddenEnum.NUMBER_1);
      },
    },
    {
      key: "hideOnExtract",
      name: tr("hideOnExtract"),
      disabled: param.thumbnailStatus == Api.HiddenEnum.NUMBER_2,
      onClick: (ev) => {
        hideMenu();
        onHideThumbnail(ev, Api.HiddenEnum.NUMBER_2);
      },
    },
    {
      key: "rotateLeft",
      name: tr("rotateLeft"),
      onClick: (ev) => {
        hideMenu();
        RotateLeft();
      },
    },
    {
      key: "rotateRight",
      name: tr("rotateRight"),
      onClick: (ev) => {
        hideMenu();
        RotateRight();
      },
    },
    {
      key: "joinDocuments",
      name: tr("joinDocuments"),
      onClick: (ev) => {
        hideMenu();
        const pagesThumnail = CacheLXApp.Thumbnails.flatMap((o) =>
          o.imageBases?.map((p) => {
            return { ...p, tabFileInfoId: o.tabFileInfoId };
          })
        );
        const pagesThumnailSelectes = pagesThumnail.filter((o) =>
          thumbnailCurrentState.selectPages.includes(o?.pageNo || 0)
        );
        JoinDocuments(pagesThumnailSelectes.map((o) => o?.tabFileInfoId || ""));
      },
    },
    {
      key: "splitDocument",
      name: tr("splitDocument"),
      onClick: (ev) => {
        handleSplitDocument();
        hideMenu();
      },
    },
    {
      key: "splitOnPublish",
      name: tr("splitOnPublish"),
      onClick: (ev) => {
        hideMenu();
        onSetSplitOnExtract(ev, true);
      },
    },
    {
      key: "removeSplit",
      name: tr("removeSplit"),
      onClick: (ev) => {
        hideMenu();
        onSetSplitOnExtract(ev, false);
      },
    },
    {
      key: "deskew",
      name: tr("deskew"),
      onClick: (ev) => {
        hideMenu();
        onDeskew(ev);
      },
    },
    {
      key: "selectAllText",
      name: tr("selectAllText"),
      onClick: (ev) => {
        hideMenu();
        selectAllTextPages();
      },
    },
    {
      key: "selectAll",
      name: tr("selectAll"),
      onClick: (ev) => {
        hideMenu();
        selectAllPages();
      },
    },
    {
      key: "delete",
      name: tr("delete"),
      onClick: (ev) => {
        hideMenu();
        handleDeletePages(ev);
        setThumbnailState((cur) => {
          return { ...cur, selectPages: [] };
        });
      },
    },
  ] as IContextualMenuItem[];
  if (param.canSplitOnPublish) {
    items = items.filter((o) => o.key != "removeSplit");
  } else {
    items = items.filter((o) => o.key != "splitOnPublish");
  }
  const pagesThumnail = CacheLXApp.Thumbnails.flatMap((o) =>
    o.imageBases?.map((p) => {
      return { ...p, tabFileInfoId: o.tabFileInfoId };
    })
  );
  const pagesThumnailSelectes = pagesThumnail.filter((o) =>
    thumbnailCurrentState.selectPages.includes(o?.pageNo || 0)
  );
  const removesplitDocument =
    thumbnailCurrentState.selectPages.length > 1 ||
    tabstate.selectedTab?.startPage === thumbnailCurrentState.selectPages[0];
  if (removesplitDocument) {
    items = items.filter((o) => o.key != "splitDocument");
  }

  const isBlankPage = pagesThumnail.find(
    (o) =>
      thumbnailCurrentState.selectPages.length == 1 &&
      o?.pageNo == thumbnailCurrentState.selectPages[0] &&
      o.type == Api.ThumbnailType.NUMBER_2
  );

  const firstElement = pagesThumnailSelectes[0];
  // tất cả các page thuộc trong 1 tab
  const allPageIsInTab = pagesThumnailSelectes.every(
    (element) => element?.tabFileInfoId === firstElement?.tabFileInfoId
  );
  // tất cả page trong tất cả các tab được chọn
  const SelectedallPageAllTab =
    pagesThumnailSelectes.length == pagesThumnail.length;

  if (!allPageIsInTab || isBlankPage || SelectedallPageAllTab) {
    items = items.filter((o) => o.key != "delete");
  }
  // show join document
  const tabSelecteds = pagesThumnailSelectes.map((o) => o?.tabFileInfoId || "");
  const tabIds = [...new Set(tabSelecteds)];
  const tabIndexs =
    docuviewareMain.DocuviewareInit?.tabDetails
      ?.filter((o) => tabIds.indexOf(o.uniqueId || "") >= 0)
      .map((o) => o.index || 0) || [];
  if (!isConsecutiveArray(tabIndexs) && tabIds.length > 1) {
    items = items.filter((o) => o.key != "joinDocuments");
  }

  return (
    <>
      <ContextualMenu target={param.targetPoint} items={items} />
    </>
  );
};
