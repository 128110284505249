import {
  DefaultButton,
  IStackTokens,
  Modal,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { TextField } from "@fluentui/react/lib/TextField";
import * as React from "react";
import { useRecoilState } from "recoil";
import * as ApiV2 from "../ApiV2";
import { ModalContent } from "../assets/style/modal/modal";
import ModalHeader from "../control/modalHeader";
import { useLang } from "../i18n/useLang";
import { TOCState, docuviewareMainState, pageSelectedState, trackingState } from "../recoil";
import { CacheLXApp } from "../App";
const BlankFileApi = new ApiV2.BlankFileApi();

const stackTokens: IStackTokens = { childrenGap: 10 };
const verticalStackTokens: IStackTokens = { childrenGap: 5 };

interface AddBlankPageModalProps {
  selectedTabIds: string[];
  isShow: boolean;
  onClose: () => void;
}

export const AddBlankPageModal = (props: AddBlankPageModalProps) => {
  const [, setPageState] = useRecoilState(pageSelectedState);
  const [, setTocCurrentState] = useRecoilState(TOCState);
  const [, setDocuviewareMain] = useRecoilState(docuviewareMainState);
  const [tracking, setTrackingState] = useRecoilState(trackingState);
  const { tr } = useLang();
  const [name, setName] = React.useState("Blank page");
  const onSubmit = async () => {
    setPageState((cur) => {
      CacheLXApp.PageState = {
        ...CacheLXApp.PageState!,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
      return { ...cur, oldCurrentPage: cur.currentPage, oldCurrentPageId: cur.PageObj?.id || "" }
    });
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: true,
        label: ``,
      },
    });
    const res = await BlankFileApi.apiLegalxtractCreateBlankFilePost({
      selectedTabIds: props.selectedTabIds,
      name: name,
    });
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: false,
        label: ``,
      },
    });
    if (!res.data) return;
    setTocCurrentState((cur) => {
      return { ...cur, tocData: res.data.tocRows || [] };
    });
    setDocuviewareMain((cur) => {
      return {
        ...cur,
        DocuviewareInit: res.data.docuviewareInit || null,
      };
    });
    onCloseModal();
  };
  const onCloseModal = () => {
    props.onClose();
  };
  return (
    <Modal isOpen={props.isShow} onDismiss={onCloseModal} isBlocking={true}>
      <ModalContent style={{ width: 550 }}>
        <div className="ms-Grid" dir="ltr">
          <Stack tokens={verticalStackTokens}>
            <ModalHeader
              title={tr("enterANewFileName")}
              onCloseModal={onCloseModal}
            />
            <TextField
              label={tr("filename")}
              value={name as any}
              autoFocus={true}
              onChange={(e: any) => setName(e.target.value)}
            />
            <Stack horizontal horizontalAlign="end" tokens={stackTokens}>
              <DefaultButton
                text={tr("cancel")}
                onClick={onCloseModal}
                allowDisabledFocus
              />
              <PrimaryButton
                text={tr("save")}
                onClick={onSubmit}
                allowDisabledFocus
              />
            </Stack>
          </Stack>
        </div>
      </ModalContent>
    </Modal>
  );
};
