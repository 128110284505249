import React, { useState, useEffect, useContext } from "react";
import { Link, Stack } from "@fluentui/react";
import * as Api from "../../../ApiV2";
import { useLang } from "../../../i18n/useLang";
import Moment from "react-moment";
import { DataCache, LocalStorageKey } from "../../../config/constant";
import { FocusedText } from "../../../utils/helpers";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { Label } from "@fluentui/react/lib/Label";
import { Icon } from "@fluentui/react/lib/Icon";
import { Commemt } from "../../../assets/style/left-sidebar/comment-panel";
import {
  AddSquareMultiple20Regular,
  SubtractSquareMultiple20Regular,
} from "@fluentui/react-icons";
import { CommentTypeEnum } from "../../../ApiV2";
import { useRecoilState } from "recoil";
import {
  commentState,
  dialogState,
  docuviewareMainState,
  IPageState,
  pageSelectedState,
} from "../../../recoil";
import { DocuVieware } from "../../../recoil/docuviewareState";
import { commentService } from "../../../service";
import { userService } from "../../../service/userService";
import { CacheLXApp } from "../../../App";
interface CommemtInterface {
  ExpandAllContent: boolean;
}
export default (props: CommemtInterface) => {
  const [comment, setCommentState] = useRecoilState(commentState);
  const [docuviewareMain] = useRecoilState<DocuVieware>(docuviewareMainState);
  const [, setDialogState] = useRecoilState(dialogState);
  const [pageState, setPageState] =
    useRecoilState<IPageState>(pageSelectedState);
  const { getCommentList, deleteComment } = commentService();
  const { tr } = useLang();
  const [ExpandAllContent, SetExpandAllContent] = useState(true);
  const [, setItemDelete] = React.useState<Api.CommentObjDisplay | null>(null);
  const { getUser } = userService();
  useEffect(() => {
    if ((docuviewareMain?.DocuviewareInit?.tabDetails || []).length > 0) {
      getCommentList();
    }
  }, [comment.OrderBy, docuviewareMain?.DocuviewareInit?.totalPage]);

  useEffect(() => {
    if (!docuviewareMain?.DocuviewareInit?.htmlString) {
      setCommentState({ ...comment, allComments: [] });
    }
  }, [docuviewareMain?.DocuviewareInit?.htmlString]);
  const Expand_Click = (Expand: boolean) => {
    SetExpandAllContent(Expand);
  };
  const Edit_click = (data: Api.CommentObjDisplay | null) => {
    setCommentState({ ...comment, dataModal: data, ShowCommentModal: "edit" });
  };
  const item_click = (parentId?: string | null) => {
    const commentParent = comment.allComments.find(
      (o) => o.commentObjDisplay?.id == parentId
    );
    if (commentParent) {
      if (
        commentParent.commentObjDisplay?.commentTypeEnum ===
        CommentTypeEnum.NUMBER_1
      ) {
        setPageState((cur) => {
          CacheLXApp.PageState = {
            ...CacheLXApp.PageState!,
            rectangleFSelected: {
              rectangleSelections: commentParent?.rectangleFs,
              color: "rgb(70 189 31 / 48%)",
              pageNo: commentParent?.commentObjDisplay?.pageNo || 0,
            },
          };
          return {
            ...cur,
            rectangleFSelected: {
              rectangleSelections: commentParent?.rectangleFs,
              color: "rgb(70 189 31 / 48%)",
              pageNo: commentParent?.commentObjDisplay?.pageNo || 0,
            },
          };
        });
        if (pageState.currentPage == commentParent?.commentObjDisplay?.pageNo) {
          FocusedText(
            commentParent?.rectangleFs || null,
            commentParent?.commentObjDisplay?.pageNo,
            DataCache.docuViewareID() || "",
            "rgb(70 189 31 / 48%)"
          );
        } else {
          DocuViewareAPI.SelectPage(
            DataCache.docuViewareID(),
            commentParent?.commentObjDisplay?.pageNo
          );
          setPageState((cur) => {
            CacheLXApp.PageState = {
              ...CacheLXApp.PageState!,
              rectangleFSelected: {
                rectangleSelections: commentParent?.rectangleFs,
                color: "rgb(70 189 31 / 48%)",
                pageNo: commentParent?.commentObjDisplay?.pageNo || 0,
              },
              currentPage: commentParent?.commentObjDisplay?.pageNo || 1,
            };
            return {
              ...cur,
              rectangleFSelected: {
                rectangleSelections: commentParent?.rectangleFs,
                color: "rgb(70 189 31 / 48%)",
                pageNo: commentParent?.commentObjDisplay?.pageNo || 0,
              },
              currentPage: commentParent?.commentObjDisplay?.pageNo || 1,
            };
          });
        }
      } else {
        const rootDocuVieware = DocuViewareAPI.Root(DataCache.docuViewareID());
        rootDocuVieware.dom.SelectedTextRegions.clear();
        const annotations = rootDocuVieware.cache.Annots as any[];
        if (!annotations) return;
        const anno = annotations.find(
          (o) =>
            o.annotApp.clientTag.indexOf(
              commentParent.commentObjDisplay?.boxId
            ) >= 0
        );
        if (anno)
          DocuViewareAPI.SelectAnnot(
            DataCache.docuViewareID(),
            anno.annotApp.id,
            true
          );
      }
    }
  };
  const onAddComment = (commentParentId?: string | null) => {
    const commentParent = comment.allComments.find(
      (o) => o.commentObjDisplay?.id == commentParentId
    );
    if (commentParent) {
      const UserInfo = getUser();
      let name = "";
      if (UserInfo) {
        name = UserInfo.userDataLogin?.result?.user_info?.fullName || "";
      }
      setCommentState({
        ...comment,
        dataModal: {
          author: name,
          parentId: commentParent.commentObjDisplay?.id,
          status: commentParent.commentObjDisplay?.commentTypeEnum,
          commentTypeEnum: commentParent.commentObjDisplay?.commentTypeEnum,
          pageNo: commentParent.commentObjDisplay?.pageNo,
        },
        ShowCommentModal: "add",
      });
    }
  };
  const onDeleteComment = (data: Api.CommentObjDisplay | null) => {
    setDialogState({
      titleDialog: "",
      MsgDialog: tr("doYouWantToDelete"),
      CallBackConfirmDialog: { action: deleteComment, param: data },
      Type: "ConfirmationDialog",
    });
    setItemDelete(data);
  };
  const CommentOrder = [
    { key: Api.CommentOrderByEnum.NUMBER_0, text: tr("page") },
    { key: Api.CommentOrderByEnum.NUMBER_1, text: tr("author") },
    { key: Api.CommentOrderByEnum.NUMBER_2, text: tr("date") },
  ];
  return (
    <Stack
      verticalAlign="center"
      style={{ height: "100%" }}
      tokens={{ childrenGap: 15 }}
    >
      <div className="header-left-panel">{tr("comment")}</div>
      <div style={{ display: "flex" }}>
        <Label style={{ marginRight: "10px" }}>{tr("sortBy")}</Label>
        <Dropdown
          style={{ width: 180 }}
          defaultSelectedKey={comment.OrderBy}
          options={CommentOrder}
          onChange={(e, option, index) => {
            setCommentState({
              ...comment,
              OrderBy:
                option?.key === undefined
                  ? Api.CommentOrderByEnum.NUMBER_2
                  : (option?.key as Api.CommentOrderByEnum), // Explicitly cast the option key to Api.CommentOrderByEnum
            });
          }}
        />
        <Link
          style={{ color: "#333", marginLeft: "auto" }}
          onClick={() => {
            Expand_Click(!ExpandAllContent);
          }}
        >
          {(ExpandAllContent && (
            <SubtractSquareMultiple20Regular></SubtractSquareMultiple20Regular>
          )) || <AddSquareMultiple20Regular></AddSquareMultiple20Regular>}
        </Link>
      </div>
      <Commemt>
        {(docuviewareMain.DocuviewareInit?.tabDetails?.length || 0) > 0 &&
          comment.allComments
            .filter((o) => !o.commentObjDisplay?.parentId)
            .map((el) => (
              <CommemtItem
                key={el.commentObjDisplay?.id}
                Edit_click={Edit_click}
                onAddComment={onAddComment}
                item_click={item_click}
                DataComment={el}
                ChildComments={
                  el.commentObjDisplay?.parentId
                    ? []
                    : comment.allComments.filter(
                        (o) =>
                          o.commentObjDisplay?.parentId ==
                          el.commentObjDisplay?.id
                      )
                }
                ExpandAllContent={ExpandAllContent}
                onDeleteComment={onDeleteComment}
              ></CommemtItem>
            ))}
      </Commemt>
    </Stack>
  );
};
interface CommentItemInterface {
  DataComment: Api.CommentObjDisplayWeb | null;
  ChildComments?: Api.CommentObjDisplayWeb[];
  ExpandAllContent: boolean;
  Edit_click: (data: Api.CommentObjDisplay | null) => void;
  item_click: (parentId?: string) => void;
  onAddComment: (parentId?: string | null) => void;
  onDeleteComment: (item: Api.CommentObjDisplay | null) => void;
}
const CommemtItem = ({
  DataComment,
  Edit_click,
  onAddComment,
  item_click,
  ExpandAllContent,
  ChildComments,
  onDeleteComment,
}: CommentItemInterface) => {
  const { editComment } = commentService();
  const [ExpandContent, SetExpandContent] = useState(ExpandAllContent);
  const { tr } = useLang();
  const Expand_Click = (Expand: boolean) => {
    SetExpandContent(Expand);
  };
  useEffect(() => {
    SetExpandContent(ExpandAllContent);
  }, [ExpandAllContent]);
  const statusCommentOptions: IDropdownOption[] = [
    {
      key: Api.CommentStatusEnum.NUMBER_0,
      text: tr("none"),
      data: { icon: "RadioBtnOff" },
    },
    {
      key: Api.CommentStatusEnum.NUMBER_1,
      text: tr("approved"),
      data: { icon: "SkypeCircleCheck" },
    },
    {
      key: Api.CommentStatusEnum.NUMBER_2,
      text: tr("rejected"),
      data: { icon: "SkypeCircleMinus" },
    },
  ];
  return (
    <>
      <div className="comment-item">
        <div
          className={`comment ${
            DataComment?.commentObjDisplay?.parentId
              ? "comment-child"
              : " comment-parent"
          }`}
          onClick={() => {
            item_click(DataComment?.commentObjDisplay?.id || "");
          }}
        >
          <div
            className={"comment-header status-Finished"}
            style={{ backgroundColor: DataComment?.commentObjDisplay?.color }}
          >
            <div
              className={"comment-toggle"}
              onClick={() => {
                Expand_Click(!ExpandContent);
              }}
            >
              <Link href="#">
                <Icon
                  iconName={ExpandContent ? "ChevronDown" : "ChevronUp"}
                ></Icon>
              </Link>
            </div>
            <div className={"comment-user"}>
              {DataComment?.commentObjDisplay?.author || ""}
            </div>
            <div className={"comment-date"}>
              <Moment format="DD/MM/YYYY hh:mm A">
                {DataComment?.commentObjDisplay?.createdDate}
              </Moment>
            </div>
          </div>
          <div
            style={{ display: ExpandContent ? "block" : "none" }}
            className={"comment-body"}
          >
            {DataComment?.commentObjDisplay?.comment}
          </div>
          <div
            style={{ display: ExpandContent ? "flex" : "none" }}
            className={"comment-footer"}
          >
            <div className="type">
              <Icon iconName={"EntitlementRedemption"}></Icon>
              <label>
                {" "}
                &nbsp;{" "}
                {tr("page") + " " + DataComment?.commentObjDisplay?.pageNo}
              </label>
            </div>
            <div style={{ flex: 1 }}></div>
            <div className="action">
              <Dropdown
                style={{ width: 125 }}
                defaultSelectedKey={DataComment?.commentObjDisplay?.status}
                onRenderTitle={onRenderTitle}
                onRenderOption={onRenderOption}
                selectedKey={DataComment?.commentObjDisplay?.status}
                options={statusCommentOptions}
                onChanged={(option, index) => {
                  const obj = DataComment?.commentObjDisplay;
                  if (obj) {
                    editComment({
                      ...obj,
                      status: option?.key as Api.CommentStatusEnum,
                    });
                  }
                }}
              />
            </div>
            <div className="action">
              <Icon
                iconName={"Reply"}
                onClick={() => {
                  if (DataComment)
                    onAddComment(
                      DataComment?.commentObjDisplay?.parentId
                        ? DataComment?.commentObjDisplay?.parentId
                        : DataComment.commentObjDisplay?.id
                    );
                }}
              ></Icon>
            </div>

            <div
              className="action"
              onClick={() => {
                if (DataComment)
                  Edit_click(DataComment?.commentObjDisplay || null);
              }}
            >
              <Icon iconName={"Edit"}></Icon>
            </div>
            <div
              className="action"
              onClick={() => {
                if (DataComment)
                  onDeleteComment(DataComment.commentObjDisplay || null);
              }}
            >
              <Icon iconName={"Delete"}></Icon>
            </div>
          </div>
        </div>
        {ChildComments?.map((element) => (
          <CommemtItemChild
            key={element.commentObjDisplay?.id}
            commentChild={element}
            Edit_click={Edit_click}
            onAddComment={onAddComment}
            item_click={() => {
              item_click(element?.commentObjDisplay?.parentId || "");
            }}
            Expand={ExpandContent}
            ExpandAllContent={ExpandContent}
            onDeleteComment={onDeleteComment}
          ></CommemtItemChild>
        ))}
      </div>
    </>
  );
};
interface CommemtItemChildInterface {
  commentChild: Api.CommentObjDisplayWeb;
  Expand: boolean;
  ExpandAllContent: boolean;
  Edit_click: (data: Api.CommentObjDisplay | null) => void;
  item_click: (parentId?: string) => void;
  onAddComment: (parentId?: string | null) => void;
  onDeleteComment: (item: Api.CommentObjDisplay | null) => void;
}
const CommemtItemChild = ({
  commentChild,
  Expand,
  ExpandAllContent,
  Edit_click,
  item_click,
  onAddComment,
  onDeleteComment,
}: CommemtItemChildInterface) => {
  return (
    <>
      <CommemtItem
        key={commentChild.commentObjDisplay?.id}
        Edit_click={Edit_click}
        onAddComment={onAddComment}
        item_click={item_click}
        ExpandAllContent={ExpandAllContent || Expand}
        onDeleteComment={onDeleteComment}
        DataComment={commentChild}
      ></CommemtItem>
    </>
  );
};
const iconStyles = { marginRight: "5px" };
const onRenderOption = (option: IDropdownOption | undefined): any => {
  let color = "";
  switch (option?.key) {
    case Api.CommentStatusEnum.NUMBER_1:
      color = "#05d458";
      break;
    case Api.CommentStatusEnum.NUMBER_2:
      color = "#da0046";
      break;
    default:
      color = "#333";
      break;
  }
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {option?.data && option?.data.icon && (
        <Icon
          style={{ ...iconStyles, color: color, fontSize: 16 }}
          iconName={option?.data.icon}
          aria-hidden="true"
        />
      )}
      <span>{option?.text}</span>
    </div>
  );
};
const onRenderTitle = (options: IDropdownOption[] | undefined): JSX.Element => {
  if (options == undefined) return <div></div>;
  const option = options[0];
  let color = "";
  switch (option?.key) {
    case Api.CommentStatusEnum.NUMBER_1:
      color = "#05d458";
      break;
    case Api.CommentStatusEnum.NUMBER_2:
      color = "#da0046";
      break;
    default:
      color = "#333";
      break;
  }
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {option.data && option.data.icon && (
        <Icon
          style={{ ...iconStyles, color: color, fontSize: 16 }}
          iconName={option.data.icon}
          aria-hidden="true"
        />
      )}
      <span>{option.text}</span>
    </div>
  );
};
